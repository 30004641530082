<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1 text-center">
            전기차량 관리 화면에 <br/> 오신것을 환영합니다
          </h2>
        </b-link>
        <!-- form -->
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >
            <!-- email -->
            <b-form-group
              label-for="email"
              label="아이디"
            >
                <b-form-input
                  id="email"
                  v-model="id"
                  name="login-email"
                  placeholder="아이디"
                  autofocus
                />
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">비밀번호</label>
              </div>
                <b-input-group
                  class="input-group-merge"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    name="login-password"
                    placeholder="비밀번호"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
            </b-form-group>

            <!-- checkbox -->
            <!-- <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                아이디 기억하기
              </b-form-checkbox>
            </b-form-group> -->

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
            >
              로 그 인
            </b-button>
          </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Constant from '@/Constant';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
export default {
  name : 'Login',
  mixins: [togglePasswordVisibility],
  data() {
    return {
      id: '',
      password: '',
      status: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    login() {
      let me = this;
      let BASEURL = Constant.BASEURL;
      let params = {
          uid: this.id,
          password : this.password
      };
      axios.post(`${BASEURL}/v1/signin`,null,{
        params : params
      })
      .then(({data})=>{
        if(data.success) {
          // 관리자 페이지 접근 불가
          if(data.data.role != "user") {
              me.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `알림`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: `사용자권한은 접근 할 수 없습니다`,
                },
              })
              return;
          }
          useJwt.setToken(data.data.token)
          let userData = { name : data.data.name,
                          nickName : data.data.nickName,
                          avatar : "/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/13-small.d796bffd.png",
                          role : data.data.role,
                          // admin, client 에 의한 설정 변경
                          ability : data.data.role == "user" ? [{action:"manage",subject:"all"}] : [{action: "read", subject: "ACL"}, {action: "read", subject: "Auth"}]
                          };
          localStorage.setItem('userData', JSON.stringify(userData))
          me.$ability.update(userData.ability)
          me.$router.replace(getHomeRouteForLoggedInUser(userData.role))
            .then(() => {
              me.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `환영합니다 ${userData.nickName}`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: `모비북에 오신것을 환용합니다(${userData.role})`,
                },
              })
            })
            .catch((err) => {
              console.log("에러 : ", err);
            })
        }
        else {
          me.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `알림`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: `아이디 혹은 비밀번호가 틀렸습니다`,
              },
            })
        }
      })
      .catch((err)=> {
          let msg = "";
          if (err.response) {
            msg = err.response.data.message;
          }
          else if (err.request) {
            console.log(err.request);
            msg = "요청이 이루어 졌으나 응답을 받지 못했습니다.";
          }
          else {
            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            msg = err.message;
          }
          me.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `알림`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: msg,
              },
            })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

[dir] .auth-wrapper.auth-v1 .auth-inner:before {
  background-image: none;
}
</style>
